import PropTypes from "prop-types";
import { createContext } from "react";
import { useParams } from "react-router";
import useSWR from "swr";
import useAuth from "../hooks/useAuth";
import fetcher from "../lib/fetcher";
import { env } from "../lib/env";

const MyProgramUsersContext = createContext(null);

export const MyProgramUsersProvider = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const { programId } = useParams();

  const { data, mutate: refreshMyProgramUsers, error } = useSWR(
    `${env.REACT_APP_API_BASE_URL}/groups/${programId}/members?following=${user._id}&pageSize=1000&sort=profile.lastName&role=expert`,
    fetcher
  );

  if (error) return <div>Sorry, something went wrong while refreshing your program users. Please try again. {error}</div>;
  // if (!data) return null;

  return (
    <MyProgramUsersContext.Provider
      value={{
        users: data?.data.users,
        refreshMyProgramUsers,
      }}
    >
      {children}
    </MyProgramUsersContext.Provider>
  );
};

MyProgramUsersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyProgramUsersContext;
