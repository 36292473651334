import { lazy, Suspense } from "react";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import { MessageComposerProvider } from "./contexts/MessageComposerContext";
import { MyProgramUsersProvider } from "./contexts/MyProgramUsersContext";
import { ProgramProvider } from "./contexts/ProgramContext";
import { UserProvider } from "./contexts/UserContext";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// Dashboard pages

const UserList = Loadable(lazy(() => import("./pages/dashboard/UserList")));
const ProgramDetails = Loadable(
  lazy(() => import("./pages/dashboard/ProgramDetails"))
);
const ProgramList = Loadable(
  lazy(() => import("./pages/dashboard/ProgramList"))
);

// User pages
const UserDetailsView = Loadable(
  lazy(() => import("./pages/user/UserProgramDetails"))
);

// Answer Page (Admin)
const AnswersList = Loadable(lazy(() => import("./pages/admin/AnswersList")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <UserList />,
      },
      {
        path: "my-users",
        children: [
          {
            path: "/",
            element: <UserList />,
          },
        ],
      },
      {
        path: "my-programs",
        children: [
          {
            path: "/",
            element: <ProgramList />,
          },
        ],
      },
      {
        path: "programs",
        children: [
          {
            path: "/:programId",
            element: (
              <ProgramProvider>
                <MyProgramUsersProvider>
                  <MessageComposerProvider>
                    <ProgramDetails />
                  </MessageComposerProvider>
                </MyProgramUsersProvider>
              </ProgramProvider>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "user",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/:userId",
        element: (
          <UserProvider>
            <UserDetailsView />
          </UserProvider>
        ),
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/answers",
        element: <AnswersList />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <UserList />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
