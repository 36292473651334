import { Alert, Button, Grid, TextField, Typography } from "@material-ui/core";

import { useState } from "react";
import { mutate } from "swr";
import PropTypes from "prop-types";
import addViewForUser from "../../utils/addViewForUser";
import updateViewForUser from "../../utils/updateViewForUser";
import { env } from "../../lib/env";

const CreateViewDialog = ({
  filters,
  onClose,
  name,
  description,
  existingViewId,
}) => {
  const [updatedName, setName] = useState(name);
  const [updatedDescription, setDescription] = useState(description);
  const [failureMessage, setFailureMessage] = useState(null);

  const handler = existingViewId
    ? () =>
        updateViewForUser(existingViewId, {
          name: updatedName,
          description: updatedDescription,
          filters,
        })
    : () =>
        addViewForUser({
          name: updatedName,
          description: updatedDescription,
          filters,
        });

  async function handleSave() {
    try {
      await handler();
      // trigger SWR refetch of ViewList records
      mutate(`${env.REACT_APP_API_BASE_URL}/users/-/view_lists`);
      onClose();
    } catch (err) {
      if (err?.response?.data?.messages) {
        setFailureMessage(
          Object.values(err.response.data.messages).flat().join("; ")
        );
      } else {
        setFailureMessage("Could not save view");
      }
    }
  }

  return (
    <>
      <Typography variant="subtitle1">Save Filters as View</Typography>
      <Grid container spacing={0}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <TextField
              label="Name"
              size="small"
              defaultValue={updatedName}
              required
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Description"
              size="small"
              defaultValue={updatedDescription}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: 2 }}
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={!updatedName}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: 2 }}
              onClick={onClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={8}>
            {failureMessage && <Alert severity="error">{failureMessage}</Alert>}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CreateViewDialog.propTypes = {
  filters: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  existingViewId: PropTypes.string,
};
export default CreateViewDialog;
