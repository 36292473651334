import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import templates from "../components/dashboard/program/messageTemplates.json";
import useAuth from "../hooks/useAuth";
import useProgram from "../hooks/useProgram";
import getDisplayNameForUser from "../utils/getDisplayNameForUser";

const initialTemplate = templates.find((template) => template._id === "custom");

const MessageComposerContext = createContext(null);

export const MessageComposerProvider = (props) => {
  const { children } = props;
  const [isMessageComposerOpen, setIsMessageComposerOpen] = useState(false);
  const [templateId, setTemplateId] = useState(initialTemplate._id);
  const [helpText, setHelpText] = useState(initialTemplate.helpText);
  const [subject, setSubject] = useState(initialTemplate.subject);
  const [message, setMessage] = useState(initialTemplate.message);
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const { user } = useAuth();
  const { program } = useProgram();

  useEffect(() => {
    const chosenTemplate = templates.find(
      (template) => template._id === templateId
    );
    const templateSubject = chosenTemplate.subject
      .replace(
        `{{currentUser.profile.displayName}}`,
        getDisplayNameForUser(user)
      )
      .replace(`{{group.name}}`, program?.name || "");
    const templateMessage = chosenTemplate.message.replace(
      `{{group.name}}`,
      program?.name || ""
    );
    setTemplateId(chosenTemplate._id);
    setHelpText(chosenTemplate.helpText);
    setSubject(templateSubject);
    setMessage(templateMessage);
  }, [templateId]);

  const resetForm = () => {
    setTemplateId(initialTemplate._id);
    setHelpText(initialTemplate.helpText);
    setSubject(initialTemplate.subject);
    setMessage(initialTemplate.message);
    setRecipients([]);
  };

  return (
    <MessageComposerContext.Provider
      value={{
        recipients,
        setRecipients,
        isMessageComposerOpen,
        setIsMessageComposerOpen,
        templateId,
        setTemplateId,
        helpText,
        setHelpText,
        subject,
        setSubject,
        message,
        setMessage,
        resetForm,
        templates,
        isInviteUserDialogOpen,
        setIsInviteUserDialogOpen,
      }}
    >
      {children}
    </MessageComposerContext.Provider>
  );
};

MessageComposerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageComposerContext;
