import axios from "../lib/axios";
import { env } from "../lib/env";

const updateViewForUser = async (viewId, { name, description, filters }) => {
  await axios.put(
    `${env.REACT_APP_API_BASE_URL}/users/-/view_lists/default/views/${viewId}`,
    JSON.stringify({ name, description, filters }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export default updateViewForUser;
