import axios from "../lib/axios";
import { env } from "../lib/env";

export const requestUserViewEmail = async ({
  viewId,
  emailRecipient,
  viewName,
}) =>
  axios.post(
    `${env.REACT_APP_API_BASE_URL}/users/-/view_lists/${viewName}/views/${viewId}`,
    JSON.stringify({ emailRecipient }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
