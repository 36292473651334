import PropTypes from "prop-types"
import { useState } from "react";
import { makeStyles, Button, Select, MenuItem } from "@material-ui/core";
import { getCountries, getCountryCallingCode } from "react-phone-number-input"
import en from "react-phone-number-input/locale/en.json"
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { FiChevronDown } from "react-icons/fi";

const countriesInOrder = getCountries().sort((a, b) => {
    if (en[a] > en[b]) return 1;
    if (en[a] < en[b]) return -1;
    return 0;
});

const useStyles = makeStyles({
    countrySelect: {
        visibility: "hidden",
        width: 0,
    },
    unicodeFlag: {
        fontSize: 30,
    }
});

const CountrySelect = ({ value, setCountryCode, setCountry, ...rest }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Select
                {...rest}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                className={classes.countrySelect}
                value={value}
                onChange={(e) => { setCountryCode(getCountryCallingCode(e.target.value)); setCountry(e.target.value) }}>
                {countriesInOrder.map((country) => (
                    <MenuItem key={country} value={country}>
                        {en[country]} +{getCountryCallingCode(country)}
                    </MenuItem>
                ))}
            </Select>
            <Button onClick={handleOpen} sx={{ height: "100%" }}>
                <div className={classes.unicodeFlag}>
                    {getUnicodeFlagIcon(value)}
                </div>
                <FiChevronDown size={20} />
            </Button>
        </>
    )
}

CountrySelect.propTypes = {
    value: PropTypes.string,
    setCountryCode: PropTypes.func.isRequired,
    setCountry: PropTypes.func.isRequired,
}

export default CountrySelect