import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { Fragment, useEffect, useState, useContext } from "react";
import useSWR from "swr";
import { useLocation, useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import fetcher from "../../lib/fetcher";
import UserViewList from "./UserViewList";
import FilterContext from "../../contexts/FilterContext";
import { env } from "../../lib/env";
import { isSuper } from "../../utils/isSuper";

const ADMIN_LIST_ITEM_IDS = {
  ANSWERS: "/admin/answers",
};

function UserViewListContainer() {
  const { filterInfo } = useContext(FilterContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userViewListData } = useSWR(
    `${env.REACT_APP_API_BASE_URL}/users/-/view_lists`, // only fetch when View Lists accordion is expanded
    fetcher
  );
  const [selectedListItemId, setSelectedListItemId] = useState(filterInfo.id);

  useEffect(() => {
    if (ADMIN_LIST_ITEM_IDS.ANSWERS === location.pathname) {
      setSelectedListItemId(ADMIN_LIST_ITEM_IDS.ANSWERS);
      return;
    }
    if (userViewListData?.data?.userViewLists?.length) {
      if (!filterInfo.id) {
        setSelectedListItemId(
          userViewListData.data?.userViewLists[0]?.views[0]?._id
        );
      } else {
        setSelectedListItemId(filterInfo.id);
      }
    }
  }, [userViewListData, filterInfo, location]);

  const userViewLists = userViewListData
    ? userViewListData.data.userViewLists
    : [];

  return (
    <List sx={{ py: 0 }}>
      {user && isSuper(user) && (
        <>
          <ListSubheader
            color="inherit"
            sx={{ py: 1, fontSize: "1rem" }}
            disableSticky
          >
            Admin
          </ListSubheader>
          <Divider />

          <ListItem
            button
            onClick={() => {
              navigate(`/admin/answers`);
              setSelectedListItemId(ADMIN_LIST_ITEM_IDS.ANSWERS);
            }}
            selected={selectedListItemId === ADMIN_LIST_ITEM_IDS.ANSWERS}
          >
            <ListItemText
              primary="Answers"
              secondary="Recently recorded videos"
            />
          </ListItem>
          <Divider />
        </>
      )}
      {userViewLists.map((vl) => (
        <Fragment key={vl._id}>
          <ListSubheader
            color="inherit"
            sx={{ py: 1, fontSize: "1rem" }}
            disableSticky
          >
            {vl.name === "default" ? "My Views" : vl.name}
          </ListSubheader>
          <Divider />
          <ListItem disableGutters sx={{ py: 0, display: "initial" }}>
            <UserViewList
              data={vl}
              selectedListItemId={selectedListItemId}
              setSelectedListItemId={setSelectedListItemId}
            />
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}

export default UserViewListContainer;
