import axios from "../lib/axios";
import { env } from "../lib/env";

const deleteViewForUser = async (viewId) => {
  await axios.delete(
    `${env.REACT_APP_API_BASE_URL}/users/-/view_lists/default/views/${viewId}`
  );
};

export default deleteViewForUser;
