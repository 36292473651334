import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { AUTH_METHODS } from "../constants";
import axios from "../lib/axios";
import responseForChallenge from "../utils/responseForChallenge";
import { env } from "../lib/env";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  authMethod: AUTH_METHODS.SMS,
};

const setToken = (token) => {
  if (token) {
    localStorage.setItem("vidscripUserToken", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  UPDATE_LOGIN_PLATFORM: (state, action) => ({
    ...state,
    authMethod: action.payload,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext(initialState);

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const vidscripUserToken = window.localStorage.getItem(
          "vidscripUserToken"
        );

        if (vidscripUserToken) {
          setToken(vidscripUserToken);

          const { data } = await axios.get(
            `${env.REACT_APP_API_BASE_URL}/auth/me`
          );

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: data.data.user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const loginWithEmailAndPassword = async (email, password) => {
    const challengeResponse = await axios.post(
      `${env.REACT_APP_API_BASE_URL}/login/mobile/auth`,
      {
        clientKey: env.REACT_APP_CLIENT_KEY,
        email,
        password,
      }
    );
    const exchangeResponse = await axios.post(
      `${env.REACT_APP_API_BASE_URL}/login/mobile/exchange`,
      {
        clientKey: env.REACT_APP_CLIENT_KEY,
        challenge: challengeResponse.data.data.challenge,
        response: responseForChallenge(challengeResponse.data.data.challenge),
      }
    );
    const vidscripUser = exchangeResponse.data.data;

    setToken(vidscripUser.token);
    dispatch({
      type: "LOGIN",
      payload: {
        user: vidscripUser.user,
        token: vidscripUser.token,
      },
    });
  };

  const requestVerificationCode = async (phoneNumber) => {
    const response = await axios.post(
      `${env.REACT_APP_API_BASE_URL}/login/sms/request`,
      {
        clientKey: env.REACT_APP_CLIENT_KEY,
        contact: phoneNumber,
      }
    );

    return response;
  };

  const loginWithVerificationCode = async (
    verificationCode,
    challenge,
    phoneNumber
  ) => {
    const exchangeResponse = await axios.post(
      `${env.REACT_APP_API_BASE_URL}/login/sms/verify`,
      {
        clientKey: env.REACT_APP_CLIENT_KEY,
        verificationCode,
        challenge,
        response: responseForChallenge(challenge),
        contact: phoneNumber,
      }
    );

    const vidscripUser = exchangeResponse.data.data;

    setToken(vidscripUser.token);
    dispatch({
      type: "LOGIN",
      payload: {
        user: vidscripUser.user,
        token: vidscripUser.token,
      },
    });
  };

  const logout = async () => {
    await axios.delete(`${env.REACT_APP_API_BASE_URL}/logout/mobile/`);
    setToken(null);
    dispatch({ type: "LOGOUT" });
  };

  const updateLoginPlatform = (authMethod) => {
    setToken(null);
    dispatch({ type: "UPDATE_LOGIN_PLATFORM", payload: authMethod });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithEmailAndPassword,
        loginWithVerificationCode,
        logout,
        requestVerificationCode,
        updateLoginPlatform,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
