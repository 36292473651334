import * as React from "react";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import DotsVertical from "../../icons/DotsVertical";
import Mail from "../../icons/Mail";
import PencilAlt from "../../icons/PencilAlt";
import Trash from "../../icons/Trash";

function UserViewMenu({ onClickEdit, onClickDelete, onClickExport, canEdit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClickEdit() {
    onClickEdit();
    handleClose();
  }

  function handleClickDelete() {
    onClickDelete();
    handleClose();
  }

  function handleClickExport() {
    onClickExport();
    handleClose();
  }

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <DotsVertical />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {canEdit &&
          <MenuItem onClick={handleClickEdit}>
            <ListItemIcon>
              <PencilAlt />
            </ListItemIcon>
               Edit
            </MenuItem>
        }
        {canEdit &&
          <MenuItem onClick={handleClickDelete}>
            <ListItemIcon>
              <Trash />
            </ListItemIcon>
                Delete
            </MenuItem>
        }
        <MenuItem onClick={handleClickExport}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          Export Data
        </MenuItem>
      </Menu>
    </>
  );
}

UserViewMenu.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default UserViewMenu;
