import { List } from "@material-ui/core";
import PropTypes from "prop-types";
import UserView from "./UserView";

const UserViewList = ({ data, selectedListItemId, setSelectedListItemId }) => {
  const { views } = data;

  return (
    <List disablePadding>
      {views &&
        views.map(({ _id, name: viewName, description, filters }) => (
          <UserView
            key={`${viewName}-${_id}`}
            name={viewName}
            description={description}
            filters={filters}
            id={_id}
            selectedListItemId={selectedListItemId}
            setSelectedListItemId={setSelectedListItemId}
            viewName={data.name}
          />
        ))}
    </List>
  );
};

UserViewList.propTypes = {
  data: PropTypes.object,
  selectedListItemId: PropTypes.string,
  setSelectedListItemId: PropTypes.func,
};

export default UserViewList;
