import { useMediaQuery } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const LogoRoot = experimentalStyled("svg")();

const vidscripGrey = "#bcbdc0";
const vidscripRed = "#eb4d4d";

const Logo = ({ isLightBackground, solidColor, ...props }) => {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const logoColors = {
    left: isDarkMode ? "white" : vidscripRed,
    right: vidscripGrey,
    text: isDarkMode ? "white" : "black",
  };

  if (isLightBackground) {
    logoColors.left = "red";
    logoColors.text = isDarkMode ? "white" : "black";
  }

  if (solidColor) {
    logoColors.left = "white";
    logoColors.right = "white";
    logoColors.text = "white";
  }

  return (
    <LogoRoot
      version="1.1"
      viewBox="0 0 2666.6667 727.27332"
      height="181.75"
      width="533.33334"
      xmlSpace="preserve"
      id="svg2"
      {...props}
    >
      <defs id="defs6">
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
          <path id="path16" d="M 0,545.455 H 2000 V 0 H 0 Z" />
        </clipPath>
        <clipPath id="clipPath66" clipPathUnits="userSpaceOnUse">
          <path id="path64" d="M 0,545.455 H 2000 V 0 H 0 Z" />
        </clipPath>
      </defs>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,727.27333)" id="g10">
        <g id="g12">
          <g clipPath="url(#clipPath18)" id="g14">
            <g transform="translate(1050.5063,273.6379)" id="g20">
              <path
                id="path22"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c 0,-59.325 -33.444,-88.889 -70.511,-88.889 -41.069,0 -72.224,30.268 -72.224,87.452 C -142.735,121.097 0,116.77 0,0 M 0.065,186.47 C 0.075,148.229 0.084,109.784 0.06,71.38 c -37.749,62.288 -168.589,59.694 -168.589,-74.216 0,-61.099 31.399,-107.884 92.265,-107.884 55.377,0 75.68,42.753 76.261,42.873 0.175,-12.309 0.029,-24.637 0.073,-36.948 8.092,-0.015 16.181,-0.005 24.278,-0.005 v 291.274 c -8.097,0 -16.19,0.02 -24.283,-0.004"
              />
            </g>
            <g transform="translate(1280.6997,225.2341)" id="g24">
              <path
                id="path26"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c 0,81.639 -143.993,41.944 -133.758,107.419 3.463,21.786 24.973,29.472 47.685,29.472 30.074,0 51.377,-12.255 55.607,-44.114 8.279,0.02 16.565,-0.111 24.846,0.068 -4.268,32.657 -17.36,47.809 -35.943,56.902 -39.665,19.183 -118.665,11.29 -118.665,-49.827 0,-87.083 141.561,-37.99 133.996,-106.018 -4.197,-31.154 -43.168,-39.021 -74.35,-31.647 -19.32,4.685 -32.949,15.734 -39.721,46.906 -8.262,-0.044 -16.525,-0.01 -24.782,-0.02 C -160.51,-90.841 0,-80.33 0,0"
              />
            </g>
            <g transform="translate(1405.5508,185.0881)" id="g28">
              <path
                id="path30"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c -49.01,0 -71.41,40.006 -71.41,88.374 0,51.047 27.383,92.846 78.865,88.227 28.185,-2.79 46.45,-22.098 53.823,-49.28 8.272,0.015 16.546,-0.005 24.823,0.01 -2.389,15.496 -7.404,31.026 -17.24,43.459 -38.146,48.758 -166.171,43.26 -166.171,-84.32 0,-62.471 32.406,-108.668 95.616,-108.668 48.235,0 81.352,26.323 91.027,78.403 -8.675,-0.005 -17.346,0 -26.016,0 C 56.876,18.877 33.038,0 0,0"
              />
            </g>
            <g transform="translate(1627.6738,384.0154)" id="g32">
              <path
                id="path34"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c -61.811,0.598 -64.928,-44.827 -69.108,-44.827 -0.189,0 0.051,21.804 -0.124,39.288 -8.081,-0.126 -16.18,0.068 -24.254,-0.097 0.092,-69.861 0.009,-139.676 0.039,-209.542 8.089,0.029 16.201,-0.058 24.302,0.044 -0.261,109.76 -13.217,188.805 68.33,188.805 C 0.321,-26.329 0.066,-29.67 0,0"
              />
            </g>
            <g transform="translate(1836.0166,362.1291)" id="g36">
              <path
                id="path38"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="M 0,0 C 46.848,0 71.595,-38.734 71.595,-85.674 71.595,-130.802 50.484,-177.4 0.712,-177.4 -94.823,-177.4 -97.047,0 0,0 m 3.799,-199.209 c 56.35,0 101.274,45.017 91.93,131.697 C 85.408,22.443 10.3,34.958 -35.784,12.76 c -28.229,-13.569 -32.96,-32.875 -35.755,-33.357 -0.048,12.303 -0.004,24.601 -0.019,36.906 -8.092,0.004 -16.181,0 -24.268,0 -0.01,-97.479 -0.005,-205.284 -0.005,-292.125 8.061,-0.005 16.174,-0.01 24.278,0 0.049,97.17 -0.73,115.14 0.683,115.14 0.049,0 20.218,-38.533 74.669,-38.533"
              />
            </g>
            <g transform="translate(756.8257,378.447)" id="g40">
              <path
                id="path42"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c -75.169,-198.585 -67.282,-181.266 -69.368,-175.486 -22.113,58.489 -44.275,116.968 -66.373,175.467 -8.782,0.014 -17.625,0.019 -26.478,0 26.706,-69.864 53.382,-139.737 80.102,-209.59 h 27.274 c 27.069,69.873 54.197,139.721 81.291,209.59 C 17.633,0.015 8.815,-0.029 0,0"
              />
            </g>
            <g transform="translate(811.6152,424.0715)" id="g44">
              <path
                id="path46"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 h 26.691 c 0.005,12.015 0.005,24.03 0.005,36.045 H -0.005 C -0.005,24.035 -0.01,12.015 0,0"
              />
            </g>
            <g transform="translate(837.1025,378.4328)" id="g48">
              <path
                id="path50"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c -8.107,-0.024 -16.215,0.087 -24.317,-0.059 0.092,-69.855 0.009,-139.675 0.039,-209.536 8.098,0.019 16.195,-0.039 24.292,0.029 C -0.024,-139.713 0.004,-69.846 0,0"
              />
            </g>
            <g transform="translate(1660.2051,424.0715)" id="g52">
              <path
                id="path54"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 h 26.696 c 0.005,12.015 0.005,24.03 0.005,36.045 H 0 C 0,24.03 -0.005,12.015 0,0"
              />
            </g>
            <g transform="translate(1685.6919,378.4328)" id="g56">
              <path
                id="path58"
                style={{
                  fill: logoColors.text,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c -8.085,0.024 -16.161,-0.024 -24.239,0.024 -0.078,-69.868 -0.015,-139.771 -0.034,-209.619 8.089,0.029 16.201,-0.058 24.302,0.043 C -0.039,-139.696 0.02,-69.853 0,0"
              />
            </g>
          </g>
        </g>
        <g id="g60">
          <g clipPath="url(#clipPath66)" id="g62">
            <g transform="translate(280.2681,502.8381)" id="g68">
              <path
                id="path70"
                style={{
                  fill: logoColors.left,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 h 0.451 c 0.003,-15.541 0.01,-31.035 0.01,-46.521 0,-12.329 -0.004,-24.652 -0.019,-36.989 -47.285,-5.199 -91.021,-34.104 -114.318,-79.038 7.289,2.319 14.597,3.408 21.734,3.408 37.835,0 70.864,-30.62 70.864,-70.965 0,-40.737 -33.38,-70.968 -70.956,-70.968 -7.129,0 -14.41,1.089 -21.642,3.4 22.253,-43.189 65.261,-73.598 114.318,-79.039 0.038,-27.839 0.009,-55.661 0.014,-83.51 H 0.019 c -120.109,9.163 -213.38,109.078 -213.38,230.121 0,121.582 94.02,221.146 213.361,230.101"
              />
            </g>
            <g transform="translate(315.2507,502.8381)" id="g72">
              <path
                id="path74"
                style={{
                  fill: logoColors.right,
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
                d="m 0,0 c 2.019,-0.409 54.429,-1.233 108.448,-36.391 64.501,-41.752 105.363,-113.807 105.363,-193.739 0,-118.64 -90.389,-220.318 -213.374,-230.092 H 0.005 c -0.005,27.9 -0.025,55.677 0.009,83.51 48.387,5.368 91.706,35.201 114.318,79.039 -7.22,-2.324 -14.499,-3.418 -21.633,-3.418 -37.473,0 -70.965,30.184 -70.965,70.965 0,40.428 33.082,70.972 70.897,70.972 7.129,0 14.426,-1.085 21.701,-3.394 C 90.748,-117.135 46.759,-88.625 0.014,-83.51 -0.02,-55.675 0.005,-27.833 0,0"
              />
            </g>
          </g>
        </g>
      </g>
    </LogoRoot>
  );
};

Logo.propTypes = {
  isLightBackground: PropTypes.bool,
  solidColor: PropTypes.bool,
};

export default Logo;
