import axios from "axios";
import { env } from "./env";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const isLoginPage =
        error.response.config.url ===
          `${env.REACT_APP_API_BASE_URL}/login/mobile/auth` ||
        error.response.config.url ===
          `${env.REACT_APP_API_BASE_URL}/login/sms/request`;
      if (error.response.status === 401 && !isLoginPage) {
        localStorage.clear();
        return (window.location.href = "/");
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
