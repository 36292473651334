import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import { LoginJWT, LoginSMS } from "../../components/authentication/login";
import Logo from "../../components/Logo";
import { AUTH_METHODS } from "../../constants";
import useAuth from "../../hooks/useAuth";
import gtm from "../../lib/gtm";

import "./Login.css";

const tabs = [
  { label: "SMS Code", value: AUTH_METHODS.SMS },
  { label: "Email", value: AUTH_METHODS.JWT },
];

const Login = () => {
  const { authMethod, updateLoginPlatform } = useAuth();
  const [currentTab, setCurrentTab] = useState(
    tabs.find((t) => t.value === authMethod)
  );

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    updateLoginPlatform(currentTab.value);
  }, [currentTab]);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(tabs.find((t) => t.value === value));
  };

  return (
    <>
      <Helmet>
        <title>Login | Vidscrip</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 4,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 100,
                  width: 300,
                }}
                isLightBackground
              />
            </RouterLink>
          </Box>
          <Card>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab.value}
              variant="scrollable"
            >
              {tabs.map((p) => (
                <Tab key={p.value} label={p.label} value={p.value} />
              ))}
            </Tabs>
            <Divider />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {authMethod === AUTH_METHODS.JWT && <LoginJWT />}
                {authMethod === AUTH_METHODS.SMS && <LoginSMS />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
