const getLocation = (user) => {
  if (!user || !user.locations) return "";

  const city = user.locations[0]?.city || "";
  const region = user.locations[0]?.region || "";
  const shouldDisplayComma = city && region;
  const cityRegion = `${city}${shouldDisplayComma && ", "}${region}`;

  return cityRegion.length > 0 ? cityRegion : `Unknown`;
};

export default getLocation;
