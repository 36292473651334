import { Popover, useMediaQuery, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import CreateViewDialog from "./CreateViewDialog";

function CreateUserViewPopover({
  anchorEl,
  isOpen,
  onClose,
  selectedOptions,
  name,
  description,
  viewId,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ border: "none", borderRadius: "none" }}
      PaperProps={{
        style: {
          width: isDesktop ? "40%" : "100%",
          border: "none",
          borderRadius: 0,
          margin: 0,
          padding: "20px",
        },
      }}
    >
      <CreateViewDialog
        filters={selectedOptions}
        onClose={onClose}
        name={name}
        description={description}
        existingViewId={viewId}
      />
    </Popover>
  );
}

CreateUserViewPopover.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  viewId: PropTypes.string,
};

export default CreateUserViewPopover;
