const getKeyForFilterType = (type) => {
  switch (type) {
    case "city":
      return "cities";
    case "location":
      return "locations";
    case "region":
      return "regions";
    case "membershipType":
      return "membershipTypes";
    case "hasInteractions":
      return "hasInteractions";
    case "isFavorite":
      return "isFavorite";
    case "embedStatus":
      return "embedStatus";
    case "enrollmentOrder":
      return "enrollmentOrder";
    case "interactionDateOrder":
      return "interactionDateOrder";
    case "manager":
      return "managers";
    case "specialty":
      return "specialties";
    case "user":
      return "users";
    case "program":
      return "programs";
    default:
      return null;
  }
};

export default getKeyForFilterType;
