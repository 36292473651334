import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'

const PhoneNumberInput = (props, ref) => (
    <TextField
        {...props}
        inputRef={ref}
        label='Mobile Number'
        name='phoneNumber'
        sx={{ flexGrow: "4" }}
        autoFocus
        type="tel"
    />
)

export default forwardRef(PhoneNumberInput)