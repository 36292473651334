import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useState } from "react";
import getKeyForFilterType from "../utils/getKeyForFilterType";
import getMembershipTypesFromProgramStatus from "../utils/getMembershipTypesFromProgramStatus";

const FilterContext = createContext(null);

const defaultFilters = {
  locations: [],
  cities: [],
  regions: [],
  programs: [],
  managers: [],
  membershipTypes: [],
  specialties: [],
  users: [],
  embedStatus: undefined,
  enrollmentStatus: undefined,
  enrollmentOrder: undefined,
  hasInteractions: undefined,
  interactionDateOrder: undefined,
  isFavorite: undefined,
};

const defaultFilterInfo = {
  id: "",
  name: "All Users",
  url: "?filters=",
};

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState([]);
  const [pageLimit, setPageLimit] = useState(25);
  const [filteredUsers, setFilteredUsers] = useState(
    JSON.parse(localStorage.getItem("filteredUsers")) || []
  );
  const [currentUserIndex, setCurrentUserIndex] = useState(
    JSON.parse(localStorage.getItem("currentUserIndex")) || 0
  );
  const [filterInfo, setFilterInfo] = useState(
    JSON.parse(localStorage.getItem("filterInfo")) || defaultFilterInfo
  );
  const [range, setRange] = useState("7");

  useEffect(() => {
    localStorage.setItem("filteredUsers", JSON.stringify(filteredUsers));
  }, [filteredUsers]);

  useEffect(() => {
    localStorage.setItem("currentUserIndex", JSON.stringify(currentUserIndex));
  }, [currentUserIndex]);

  useEffect(() => {
    localStorage.setItem("filterInfo", JSON.stringify(filterInfo));
  }, [filterInfo]);

  const handleFilterChange = (selected) => {
    setPage(0);
    if (!selected) {
      setFilters({ ...defaultFilters });
      setQuery([]);
      return;
    }

    const newFilters = { ...defaultFilters };

    selected.forEach(({ type, value }) => {
      switch (type) {
        case "membershipType":
          newFilters.membershipTypes = [
            ...newFilters.membershipTypes,
            ...getMembershipTypesFromProgramStatus(value),
          ];
          break;
        case "hasInteractions":
        case "embedStatus":
        case "isFavorite":
          newFilters[getKeyForFilterType(type)] = value;
          break;
        default:
          newFilters[getKeyForFilterType(type)] = [
            ...(newFilters[getKeyForFilterType(type)] || []),
            value.replace("&", "%20"),
          ];
          break;
      }
    });

    setFilters(newFilters);
    setQuery(selected);
  };

  const handleDeleteFilter = useCallback(
    (option) => {
      let index = query.findIndex((q) => q.value === option.value);

      if (index > -1) {
        if (query.length === 1) {
          setFilterInfo(defaultFilterInfo);
        }

        // TODO: Reduce redudnacy. Query and Filters are the same thing.
        query.splice(index, 1);
        setQuery([...query]);

        const newFilters = { ...filters };
        const type = getKeyForFilterType(option.type);

        switch (type) {
          case "membershipTypes":
            getMembershipTypesFromProgramStatus(option.value).forEach(
              (membershipType) => {
                index = newFilters[type].indexOf(membershipType);
                if (index > -1) {
                  newFilters[type].splice(index, 1);
                }
              }
            );
            break;
          case "isFavorite":
          case "embedStatus":
          case "hasInteractions":
            newFilters[type] = undefined;
            break;
          default:
            index = newFilters[type].indexOf(option.value);
            if (index > -1) {
              newFilters[type].splice(index, 1);
            }
            break;
        }

        setFilters({ ...newFilters });
      }
    },
    [query, filters]
  );

  return (
    <FilterContext.Provider
      value={{
        defaultFilters,
        filters,
        setFilters,
        handleFilterChange,
        handleDeleteFilter,
        page,
        setPage,
        pageLimit,
        setPageLimit,
        query,
        setQuery,
        getMembershipTypesFromProgramStatus,
        filteredUsers,
        setFilteredUsers,
        currentUserIndex,
        setCurrentUserIndex,
        filterInfo,
        setFilterInfo,
        range,
        setRange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

FilterContext.displayName = "FilterContext";

export default FilterContext;
