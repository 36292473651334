import { useContext, useMemo, useRef, useState } from "react";
import {
  Button,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import PropTypes from "prop-types";
import deleteViewForUser from "../../utils/deleteViewForUser";
import UserViewMenu from "./UserViewMenu";
import CreateUserViewPopover from "./CreateUserViewPopover";
import ExportUserViewPopover from "./ExportUserViewPopover";
import FilterContext from "../../contexts/FilterContext";
import { env } from "../../lib/env";

function stripDelimiters(value) {
  if (!value) return "";

  return value.replace(/[,:]+/g, "");
}

function encodeFilters(data) {
  // [{ label, type, value }, ...] -> LABEL:TYPE:VALUE,...
  return data
    .map(
      ({ label, type, value }) =>
        `${stripDelimiters(label)}:${stripDelimiters(type)}:${stripDelimiters(
          value
        )}`
    )
    .join(",");
}

function serializeFilters(data) {
  return data.map(({ label, type, value }) => ({
    label,
    type,
    value,
  }));
}

const UserView = ({
  id,
  name,
  description,
  filters,
  selectedListItemId,
  setSelectedListItemId,
  viewName,
}) => {
  const { setFilterInfo } = useContext(FilterContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const anchorEl = useRef();

  const canEdit = viewName !== "Programs";

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const urlQuery = useQuery();
  const navigate = useNavigate();

  function handleClick(data) {
    const urlFilters = encodeFilters(serializeFilters(data));
    urlQuery.set("filters", urlFilters);
    const filter = {
      url: `?${urlQuery.toString()}`,
      name,
      id,
    };
    setFilterInfo(filter);
    navigate(`/?${urlQuery.toString()}`);
  }

  async function handleDeleteView() {
    await deleteViewForUser(id);
    setIsConfirmDeleteOpen(false);
    // trigger SWR refetch of ViewList records
    mutate(`${env.REACT_APP_API_BASE_URL}/users/-/view_lists`);
  }

  return (
    <>
      <ListItem
        button
        onClick={() => {
          handleClick(filters);
          setSelectedListItemId(id);
        }}
        ref={anchorEl}
        selected={selectedListItemId === id}
      >
        <ListItemText primary={name} secondary={description} />
        <ListItemSecondaryAction>
          <UserViewMenu
            onClickEdit={() => setIsModalOpen(true)}
            onClickDelete={() => setIsConfirmDeleteOpen(true)}
            onClickExport={() => setIsExportModalOpen(true)}
            canEdit={canEdit}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <CreateUserViewPopover
        anchorEl={anchorEl}
        selectedOptions={filters}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        name={name}
        description={description}
        viewId={id}
      />
      <Popover
        open={isConfirmDeleteOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsConfirmDeleteOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ border: "none", borderRadius: "none" }}
        PaperProps={{
          style: {
            width: isDesktop ? "40%" : "100%",
            border: "none",
            borderRadius: 0,
            margin: 0,
            padding: "20px",
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item xs={12}>
              <Typography variant="h5">Are you sure?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Do you really want to delete this View?</Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: 2 }}
                onClick={handleDeleteView}
                color="primary"
                variant="contained"
              >
                Yes, Delete
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: 2 }}
                onClick={() => setIsConfirmDeleteOpen(false)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
      <ExportUserViewPopover
        anchorEl={anchorEl}
        selectedOptions={filters}
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        name={name}
        description={description}
        viewId={id}
        viewName={viewName}
      />
    </>
  );
};

UserView.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  filters: PropTypes.array.isRequired,
  selectedListItemId: PropTypes.string,
  setSelectedListItemId: PropTypes.func,
  viewName: PropTypes.string,
};

export default UserView;
