import crypto from "crypto";
import { env } from "../lib/env";

const responseForChallenge = (challenge) =>
  crypto
    .createHmac("sha512", Buffer.from(env.REACT_APP_CLIENT_SECRET, "utf-8"))
    .update(Buffer.from(challenge, "utf-8"))
    .digest("hex");

export default responseForChallenge;
