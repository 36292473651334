export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const PROGRAM_STATUS = {
  NA: "N/A",
  UNKNOWN: "Unknown",
  ACTIVE: "Active",
  PENDING: "Pending",
  NEW: "Target",
  INVITED: "Invited",
  INTRODUCTION_SCHEDULED: "Intro. Scheduled",
  RECORDING_SCHEDULED: "Rec. Scheduled",
  INCOMPLETE: "Incomplete",
  RECORDED: "Recorded",
  RECORDING_CANCELLED: "Rec. Cancelled",
  ENROLLING: "Enrolling",
  INTRODUCTION_CANCELLED: "Intro. Cancelled",
};

export const AUTH_METHODS = {
  JWT: "JWT",
  SMS: "SMS",
};

export const PROGRAM_ENROLLMENT_STATUS = {
  NA: "N/A",
  NOT_ENROLLING: "Not Enrolling",
  READY_TO_ENROLL: "Ready to Enroll",
  ENROLLING: "Enrolling",
};

export const USER_EMBED_STATUS = {
  NO_EMBED: 0,
  EMBED: 1,
};

export const USER_ROLE = {
  PATIENT: "patient",
  USER: "expert",
  AP: "vidscrip_manager",
  PRODUCER: "vidscrip_admin",
  ADMIN: "super_admin",
};

export const USER_FAVORITE_STATUS = {
  NORMAL: 0,
  FAVORITE: 1,
};

export const USER_INTERACTIONS_STATUS = {
  NO_INTERACTIONS: 0,
  HAS_INTERACTIONS: 1,
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const USER_ROLES = {
  USER: "expert",
  MANAGER: "vidscrip_manager",
  ADMIN: "vidscrip_admin",
  SUPER_ADMIN: "super_admin",
};

export const CALENDARS = {
  INTRODUCTION: "introduction",
  RECORDING: "recording",
};

export const TABLE_COLUMNS = {
  USERS: {
    name: "users",
    label: "User",
    width: "20%",
    align: "left",
  },
  PROGRAMS: {
    name: "programs",
    label: "Program",
    width: "25%",
    align: "left",
  },
  INTERACTIONS: {
    name: "interactions",
    label: "Interactions",
    width: "27%",
    align: "left",
  },
  MEMBERSHIP_TYPES: {
    name: "membershipTypes",
    label: "Status",
    width: "5%",
    align: "left",
  },
  ENROLLMENTS: {
    name: "enrollments",
    label: "Enrollments",
    width: "5%",
    align: "left",
  },
  NPS: {
    name: "nps",
    label: "NPS (Responses)",
    width: "5%",
    align: "left",
  },
  VIEW_TIME: {
    name: "viewTime",
    label: "View Time",
    width: "13%",
    align: "left",
  },
};

export const SECTIONS = {
  USERS: "users",
  PROGRAMS: "programs",
  // MANAGER: "managers",
  INTERACTIONS: "interactions",
  MEMBERSHIP_TYPES: "membershipTypes",
  EMBED_STATUS: "embedStatus",
  ENROLL_STATUS: "enrollStatus",
  ENROLLMENTS: "enrollments",
  NPS: "nps",
  VIEW_TIME: "viewTime",
};

export const TREND_DIRECTION = {
  UP: "up",
  DOWN: "down",
};
