import { PROGRAM_STATUS } from "../constants";

const {
  NA,
  NEW,
  INVITED,
  UNKNOWN,
  INTRODUCTION_SCHEDULED,
  INTRODUCTION_CANCELLED,
  RECORDING_SCHEDULED,
  RECORDING_CANCELLED,
  RECORDED,
  INCOMPLETE,
  PENDING,
  ENROLLING,
} = PROGRAM_STATUS;

const getMembershipTypesFromProgramStatus = (programStatus) => {
  switch (programStatus) {
    case NA:
      return ["owned"];
    case INVITED:
      return ["invited"];
    case INTRODUCTION_SCHEDULED:
      return ["introduction_scheduled"];
    case INTRODUCTION_CANCELLED:
      return ["introduction_cancelled"];
    case RECORDING_SCHEDULED:
      return ["recording_scheduled"];
    case RECORDING_CANCELLED:
      return ["recording_cancelled"];
    case RECORDED:
      return ["complete"];
    case ENROLLING:
      return ["enrolling"];
    case INCOMPLETE:
      return ["incomplete"];
    case PENDING:
      return ["pendingUpdate"];
    case NEW:
      return ["pendingMembership", "member", "in"];
    default:
      return UNKNOWN;
  }
};

export default getMembershipTypesFromProgramStatus;
