import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { createContext, useState } from "react";
import { useParams } from "react-router";
import useSWR from "swr";
import useAuth from "../hooks/useAuth";
import fetcher from "../lib/fetcher";
import { env } from "../lib/env";

const ProgramContext = createContext(null);

export const ProgramProvider = (props) => {
  const { children } = props;
  const [isCalendarSelectDialogOpen, setIsCalendarSelectDialogOpen] = useState(
    false
  );
  const [isEmbedCodeDialogOpen, setIsEmbedCodeDialogOpen] = useState(false);
  const [isProgramLoading, setIsProgramLoading] = useState(true);
  const [chosenUser, setChosenUser] = useState(null);
  const { user } = useAuth();
  const { programId } = useParams();

  const { data: programResponse, error: programError } = useSWR(
    `${env.REACT_APP_API_BASE_URL}/groups/${programId}?populate=["specialties", "whitelistedCountries"]`,
    fetcher
  );

  const {
    data: membersResponse,
    mutate: refreshProgram,
    error: membersError,
  } = useSWR(
    `${env.REACT_APP_API_BASE_URL}/groups/${programId}/members?sort=profile.lastName&role=expert&pageSize=2000&pageNum=0`,
    fetcher
  );

  const { data: nonMembersResponse, error: nonMembersError } = useSWR(
    () =>
      `${env.REACT_APP_API_BASE_URL}/groups/${programResponse.data.group._id}/non_members?pageSize=1000&sort=profile.lastName&following=${user._id}`,
    fetcher
  );

  if (programError) {
    return (
      <Typography color="error">
        Something went wrong while fetching the programs. Please, try refreshing the page. {programError}
      </Typography>
    );
  }
  if (membersError) {
    return (
      <Typography color="error">
        Something went wrong while fetching the program members. Please, try refreshing the page. {membersError}
      </Typography>
    );
  }

  if (nonMembersError) {
    return (
      <Typography color="error">
        Something went wrong while fetching non members. Please, try refreshing the page. {nonMembersError}
      </Typography>
    );
  }

  if (
    isProgramLoading &&
    programResponse &&
    membersResponse &&
    nonMembersResponse
  ) {
    setIsProgramLoading(false);
  }

  return (
    <ProgramContext.Provider
      value={{
        isCalendarSelectDialogOpen,
        setIsCalendarSelectDialogOpen,
        isEmbedCodeDialogOpen,
        setIsEmbedCodeDialogOpen,
        isProgramLoading,
        chosenUser,
        setChosenUser,
        program: programResponse?.data.group,
        users: membersResponse?.data.users,
        nonMemberUsers: nonMembersResponse?.data.users,
        refreshProgram,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

ProgramProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProgramContext;
