import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { requestUserViewEmail } from "../../utils/requestUserViewEmail";

const ExportViewDialog = ({ onClose, name, existingViewId, viewName }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: user.email ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { data } = await requestUserViewEmail({
          viewId: existingViewId,
          emailRecipient: values.email,
          viewName,
        });
        if (data.success) {
          enqueueSnackbar(
            `${values.email} will be emailed a CSV of ${name} user data. The time to receive this email varies depending on data size.`,
            {
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              variant: "success",
            }
          );
          onClose();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h6">{`Export "${name}"`}</Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={6}>
            <form onSubmit={formik.handleSubmit} id="export-view">
              <TextField
                fullWidth
                size="small"
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            </form>
          </Grid>
          <Grid item sm={3}>
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              color="primary"
              variant="contained"
              form="export-view"
              type="submit"
            >
              Export
            </Button>
          </Grid>
          <Grid item sm={3}>
            <Button
              fullWidth
              onClick={onClose}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ExportViewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string,
  existingViewId: PropTypes.string,
  viewName: PropTypes.string,
};
export default ExportViewDialog;
