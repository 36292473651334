import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import ChevronLeft from "../../icons/ChevronLeft";
import ChevronRight from "../../icons/ChevronRight";
import Menu from "../../icons/Menu";
import getDisplayNameForUser from "../../utils/getDisplayNameForUser";
import getLocation from "../../utils/getLocation";
import Logo from "../Logo";
import Scrollbar from "../Scrollbar";
import DashboardNavbar from "./DashboardNavbar";
import UserViewListContainer from "./UserViewListContainer";

const drawerWidth = 280;

const Main = experimentalStyled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isDesktop",
})(({ theme, open, isDesktop }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: isDesktop ? `${drawerWidth}px` : 0,
  }),
}));

const AppBar = experimentalStyled(DashboardNavbar)(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
}));

export const DashboardContext = createContext(null);

const DashboardLayout = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (isDesktop) {
      setOpen(true);
    }
  }, [isDesktop]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const sidebarContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "95%",
        "& > div > div": {
          "&.ps__rail-y": {
            zIndex: 2,
          },
        },
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }} style={{ height: "95%" }}>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "background.default",
            borderRadius: 1,
            display: "flex",
            overflow: "hidden",
            p: 2,
          }}
        >
          <Avatar
            src={user.profile.photoUrlString}
            sx={{
              height: 48,
              width: 48,
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {getDisplayNameForUser(user)}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {getLocation(user)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <UserViewListContainer user={user} />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        onSidebarMobileOpen={handleDrawerOpen}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <Menu />
          </IconButton>
          <Logo />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            overflowY: "hidden",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "inherit" }}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {sidebarContent}
      </Drawer>
      <Main open={open} isDesktop={isDesktop}>
        <DrawerHeader />
        <DashboardContext.Provider
          value={{
            open,
            drawerWidth,
          }}
        >
          <Outlet />
        </DashboardContext.Provider>
      </Main>
    </>
  );
};

export default DashboardLayout;
