import { Box, Button, FormHelperText, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { useState } from "react";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import 'react-phone-number-input/style.css'
import PhoneNumberInput from "./PhoneNumberInput"
import CountrySelect from './CountrySelect'

const LoginSMS = (props) => {
  const isMountedRef = useIsMountedRef();
  const { loginWithVerificationCode, requestVerificationCode } = useAuth();
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [challenge, setChallenge] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [country, setCountry] = useState("US");

  return (
    <>
      {!isVerifyingCode && (
        <Formik
          initialValues={{
            phoneNumber: "",
            submit: null,
          }}
          validate={(value) => {
            const errors = {};
            if (!isValidPhoneNumber(value.phoneNumber)) {
              errors.phoneNumber = "Enter a valid phone number"
            }
            return errors
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const response = await requestVerificationCode(
                values.phoneNumber
              );
              setPhoneNumber(
                values.phoneNumber
              );
              setChallenge(response.data.data.challenge);

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
                setIsVerifyingCode(true);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({
                  submit:
                    "The mobile number you entered did not match our records. Please double-check and try again.",
                });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              {errors.submit && (
                <Box sx={{ mt: 1, mb: 1.5 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2.3 }}>
                <PhoneInput
                  value={`+${countryCode}${values.phoneNumber}`}
                  onChange={(e) => { values.phoneNumber = e; }}
                  style={{ width: "100%" }}
                  inputComponent={PhoneNumberInput}
                  countrySelectComponent={() => CountrySelect({ value: country, setCountryCode, setCountry })}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {isVerifyingCode && (
        <Formik
          initialValues={{
            verificationCode: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            verificationCode: Yup.string()
              .length(6, "Verification code must be six digits")
              .required("Verification code required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              await loginWithVerificationCode(
                values.verificationCode,
                challenge,
                phoneNumber
              );

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <InputMask
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.verificationCode}
                mask="999999"
                maskPlaceholder={null}
              >
                <TextField
                  autoFocus
                  error={Boolean(
                    touched.verificationCode && errors.verificationCode
                  )}
                  fullWidth
                  helperText={
                    touched.verificationCode && errors.verificationCode
                  }
                  label="Verification Code"
                  name="verificationCode"
                  variant="outlined"
                  autoComplete="one-time-code"
                />
              </InputMask>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default LoginSMS;
