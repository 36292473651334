import { createContext, useState } from "react";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import useSWR from "swr";
import useAuth from "../hooks/useAuth";
import fetcher from "../lib/fetcher";
import { env } from "../lib/env";

const UserContext = createContext(null);

export const UserProvider = (props) => {
  const { userId } = useParams();
  const { user } = useAuth();
  const { children } = props;
  const [isUserLoading, setIsUserLoading] = useState(true);
  const populate = ["users.owner.locations", "users.owner.specialties"].join(
    '","'
  );

  const select = [
    "users.owner._id",
    "users.owner.email",
    "users.owner.profile",
    "users.owner.role",
  ].join('","');

  const {
    data: programUsersResponse,
    mutate: refreshUser,
    error: userDataError,
  } = useSWR(
    `${env.REACT_APP_CONNECT_BASE_URL}/analytics/users/${
      user._id
    }?populate=["${populate}"]&select=["${select}"]&page=0&limit=1&filters=${JSON.stringify(
      {
        users: [userId],
      }
    )}`,
    fetcher
  );

  if (userDataError) {
    return (
      <Typography color="error">
        Something went wrong while loading this user! Please, try refreshing the page. {userDataError}
      </Typography>
    );
  }

  if (isUserLoading && programUsersResponse) {
    setIsUserLoading(false);
  }

  const { data, error } = useSWR(
    `${env.REACT_APP_API_BASE_URL}/users/${user._id}/program_users_options`,
    fetcher
  );

  if (!data && !error) return null;
  if (error)
    return (
      <Typography color="error">
        {error && `Something went wrong fetching the program user options! Please, try refreshing the page.`}
      </Typography>
    );

  const programs = data.data.programs.map((program) => ({
    ...program,
    managers: data.data.managers.filter(({ memberships }) =>
      memberships.some((m) => m.program === program._id)
    ),
  }));

  return (
    <UserContext.Provider
      value={{
        isUserLoading,
        fetchedUser: programUsersResponse?.users[0],
        refreshUser,
        programs,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
